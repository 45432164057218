import React, { useCallback, useEffect } from "react";
import { Autocomplete } from '@mui/material';
import t from '../../helpers/languages';
import TextField from '@mui/material/TextField';
import { debounce } from "lodash";
import { getAllCodingSystems } from '../../services/codingSystemsService';

export default function FilterCodingSystems({
    codingSystem: initialCodingSystem,
    getSelectedCodingSystem,
    errorMessage
}) {

    const [codingSystem, setCodingSystem] = React.useState(0);
    const [openCodingSystem, setOpenCodingSystem] = React.useState(false);
    const [codingSystems, setCodingSystems] = React.useState([]);
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        setCodingSystem(initialCodingSystem  && initialCodingSystem.id !== null ? initialCodingSystem : 0);
        setCodingSystems(initialCodingSystem  && initialCodingSystem.id !== null ? [initialCodingSystem] : []);
    }, [initialCodingSystem]);


    const handleInputChange = (e, fetchType) => {

        const value = e && e.target && e.target.value;
        if (value && value.length >= 3 ) {
            setSearch(value);
            debounceFn(value)
        };
    };
    const randomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    const handleDebounceFn = (val) => {
        codingSystemSearch({
            search: val,
        })
    };
    const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

    const onCodingSystemSelectionChange = (event, values) => {
        setCodingSystem(values ? values : '');
        const selectedCodingSystem = {
            codingSystem: values ? values : null,
        };
        getSelectedCodingSystem(selectedCodingSystem);

    };

    const codingSystemSearch = (criteria) => {

        return getAllCodingSystems(criteria).then((res) => {
            setCodingSystems(res.data);
        }).catch((error) => {
        })
    }

    return (
        <div>
            <Autocomplete
                // set the width to the input length multiplied by the x height
                // it's not quite right but gets it close
                id={"find-codingSystem" + randomNumber(0, 999)}
                fullWidth
                open={openCodingSystem}
                onOpen={() => {
                    setOpenCodingSystem(true);
                }}
                onClose={() => {
                    setOpenCodingSystem(false);
                }}
                openOnFocus={true}
                onInputChange={e => handleInputChange(e)}
                onChange={onCodingSystemSelectionChange}
                value={codingSystems.find(v => v.id === codingSystem.id) || null}
                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                getOptionLabel={(option) => (option.name !== null ? option.name : '')}
                options={codingSystems !== null ? codingSystems : null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('CODING')}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps
                        }}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                )}
            />
        </div>
    );
};
