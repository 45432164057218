
const tableHeadersProductInventory = [
  { id: 'orderItem', disablePadding: false, label: 'Order item',width: '25%' },
  { id: 'orderId', disablePadding: false, label: 'Order Id', width: '5%' },
  { id: 'rfid', disablePadding: false, label: 'Rfid', width: '10%' },
  { id: 'quantity', disablePadding: false, label: 'Quantity', width: '8%' },
  { id: 'lastScan', disablePadding: false, label: 'Last scan', width: '10%' },
  { id: 'status', disablePadding: false, label: 'Status', align:'center',width: '5%' },
  { id: 'store', disablePadding: false, label: 'Store', width: '10%' },
  { id: 'notes', disablePadding: false, label: 'Notes', width: '23%' },
  { id: 'actions', disablePadding: false, label: 'Actions', width: '5%' },
];

// Empty product inventory
const newProductInventory = () => {
  return {
    id: '?',
    orderItem: { name: null, id: null },
    rfid: null,
    quantity: null,
    lastScan: null,
    status: null,
    vessel: { name: null, id: null },
    notes: null,
  }
};

export { tableHeadersProductInventory, newProductInventory };
