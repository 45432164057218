import React, {useMemo} from 'react'
import './ItemsStatusIndicator.scss'
import _ from 'lodash';

const ItemsStatusIndicator = props => {


    const statusConfigurationMappings = {
        //"info": ['Expected'],
        "warning": ['exhausted','expired'],
        "init" : ['expected'],
        "success": ['scanned'],
        "declined": ['broken'],
        "error": ['missing','lost']
    }


    const getIndicationFlag = useMemo(() => {
        let status = null;
        Object.keys(statusConfigurationMappings).forEach(key => {
            if(statusConfigurationMappings[key].includes(_.lowerCase(props.status))) {
                status = key
            }
        })

        return status
        // eslint-disable-next-line  
    }, [props.status])

    return (
        <div className={`items-status-indicator items-status-indicator--${getIndicationFlag}`}>
            {props.children}
        </div>
    )
}

export {ItemsStatusIndicator}