import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { debounce, Grid, TextField } from "@mui/material";
import Switch from '@mui/material/Switch';
import FilterCodingSystems from "../../components/filterCodingSystems";
import FilterCatalogues from "../../components/filterCatalogues";

import './_productTableFilter.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing('1px'),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing('2px'),
  },
}));

export const ProductTableFilter = ({
  search: initialSearch,
  codingSystem: initialCodingSystem,
  catalogue: initialCatalogue,
  isUnresolved: initialIsUnresolved,
  enabledIsUnresolved,
  isDiscountable: initialIsDiscountable,
  getFilterData
}) => {

  const classes = useStyles();

  const [search, setSearch] = useState(initialSearch || '');
  const [codingSystem, setCodingSystem] = useState(initialCodingSystem || null);
  const [catalogue, setCatalogue] = useState(initialCatalogue || null);
  const [isUnresolved, setIsUnresolved] = useState(initialIsUnresolved || false);
  const [isDiscountable, setIsDiscountable] = useState(initialIsDiscountable || false);


  const handleChange = (event, property) => {
    if (property === "isUnresolved") {
      setIsUnresolved(event.target.checked);
      const filterData = {
        isUnresolved: event.target.checked,
        isDiscountable: isDiscountable,
        category: catalogue,
        coding: codingSystem,
        search: search
      };

      debounceFn(filterData);
    } else if (property === "isDiscountable") {
      setIsDiscountable(event.target.checked);
      const filterData = {
        isUnresolved: isUnresolved,
        isDiscountable: event.target.checked,
        category: catalogue,
        coding: codingSystem,
        search: search
      };

      debounceFn(filterData);
    }
    else {
      setSearch(event.target.value);
      const filterData = {
        isUnresolved: isUnresolved,
        isDiscountable: isDiscountable,
        category: catalogue,
        coding: codingSystem,
        search: event.target.value
      };

      debounceFn(filterData);
    }
  };

  const handleDebounceFn = (val) => {
    getFilterData(val)
  };
  const debounceFn = useCallback(debounce(handleDebounceFn, 800), []);

  const selectedCodingSystem = (data) => {
    setCodingSystem(data.codingSystem);
    const filterData = {
      isUnresolved: isUnresolved,
      isDiscountable: isDiscountable,
      coding: data.codingSystem,
      category: catalogue,
      search: search
    };

    getFilterData(filterData);
  }

  const selectedCatalogue = (data) => {
    setCatalogue(data.catalogue);
    const filterData = {
      isUnresolved: isUnresolved,
      isDiscountable: isDiscountable,
      coding: codingSystem,
      category: data.catalogue,
      search: search
    };

    getFilterData(filterData);
  }

  return (
    <form >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              value={search}
              variant="outlined" className={classes.formControl}
              onChange={e => handleChange(e, 'search')}
              name="search"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <FilterCatalogues
              catalogue={catalogue}
              getSelectedCatalogue={selectedCatalogue}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FilterCodingSystems
              codingSystem={codingSystem}
              getSelectedCodingSystem={selectedCodingSystem}
            />
          </FormControl>
        </Grid>
        {enabledIsUnresolved&&<Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              variant="outlined"
              control={
                <Switch
                  checked={isUnresolved}
                  onChange={e => handleChange(e, 'isUnresolved')}
                  name="isUnresolved"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              }
              label="IsUnresolved"
            />
          </FormControl>
        </Grid>}
        <Grid item xs={2} >
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              variant="outlined"
              control={
                <Switch
                  checked={isDiscountable}
                  onChange={e => handleChange(e, 'isDiscountable')}
                  name="isDiscountable"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              }
              label="isDiscountable"
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}